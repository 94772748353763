import * as React from "react"
import { css } from "linaria"
import { graphql } from "gatsby"

import { blogWidth, fontFamily } from "../consts"
import { Layout } from "../components/layout"
import { BlogPosts } from "../components/blog-posts"

const cn = {
  wrapper: css`
    max-width: ${blogWidth};
    margin: 0 auto;
  `,
  noPosts: css`
    ${fontFamily}
    text-align: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    margin-top: 0.5rem;
  `,
}

const BlogIndex = ({ data, location }) => {
  const posts = data.posts.nodes;
  return (
    <Layout location={location} title={`Blog`}>
      <div className={cn.wrapper}>
        {
          posts.length > 0 ?
            <BlogPosts posts={posts} /> : 
            <div className={cn.noPosts}>No posts so far</div>
        }
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC}
      filter: {frontmatter: {section: {eq: "blog"}}}
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD MMMM YYYY")
          title
          description
        }
      }
    }
  }

`
