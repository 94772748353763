import * as React from "react"
import { Link } from "gatsby"
import { css } from "linaria"

import { linkStyle } from "../consts";

export const cn = {
  header: css`
    text-align: center;
    font-family: 'Righteous', cursive;
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    margin-top: 0.5rem;
  `,
  blogContent: css`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto 1.4rem;
    flex-direction: column;
    font-family: 'Quantico', sans-serif;
  `,
  posts: css`
    margin-top: 1rem;
    padding: 0 1rem;
    background: var(--text-background);
    color: var(--text-color);
  `,
  post: css`
    margin: 1rem 0;
    font-size: 1.1rem;
    line-height: 1.3;
    color: #fff;
  `,
  title: css`
    display: block;
    font-weight: bold;
    margin: 0 0 0.5rem;
    font-size: 1.3rem;
    text-transform: uppercase;

    ${linkStyle}
  `,
  body: css``,
}

export const BlogPosts = ({ posts }) => (
  <div className={cn.blogContent}>
    <h1 className={cn.header}>Blog</h1>
    <div className={cn.posts}>
      {
        posts.map(post => (
          <div className={cn.post} key={`post-${post.fields.slug}`}>
            <div className={cn.title}>
              <Link to={`/blog${post.fields.slug}`}>
                {post.frontmatter.title}
              </Link>
            </div>              
            <div className={cn.body}>
              {post.frontmatter.description || post.excerpt}
            </div>
          </div>
        ))
      }
    </div>
  </div>
)
